import React, { useState } from "react";
import { Link } from "react-router-dom";
import decode from 'jwt-decode';
import Swal from 'sweetalert2';

const Login = ({ history }) => {
   // const [loginData, setLoginData] = useState({});
   // const handleBlur = (e) => {
   //    const newLoginData = { ...loginData };
   //    newLoginData[e.target.name] = e.target.value;
   //    setLoginData(newLoginData);
   // };
   // const submitHandler = (e) => {
   //    e.preventDefault();
   //    history.push("/");
   // };

   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')

   const handleEmailChange = (event) => setEmail(event.target.value)
   const handlePasswordChange = (event) => setPassword(event.target.value)

   async function logn() {
      // await fetch(process.env.REACT_APP_API_LOGIN, {
      // await fetch(`http://localhost:5000/api/user/login`, {
      await fetch(`https://temsemjvback-919fcf8b1bde.herokuapp.com/api/user/login`, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({email, password})
      })
          .then(function (result) {
              if (result['ok'] === true) {
                  result.text().then(function (data) {
                      localStorage.setItem('token', data)
                      const tok = JSON.parse(data)
                      localStorage.setItem('tokenT', tok.token)
                      console.log(tok.token);
                      Swal.fire({
                          title: "Bienvenido!",
                          text: "Redireccionando en 2 segundos.",
                          timer: 2000,
                          showConfirmButton: false,
                          icon: 'success'
                      }).then((result) => {
                        if (result) {
                           window.location.href = "/tematicas";
                       }
                      });
                  })

              } else {
                  result.text().then(function (data) {
                      Swal.fire({
                          icon: 'error',
                          title: '¡ERROR!',
                          text: data,
                          timer: 3500
                      })
                  })
              }
          }).catch(function (error) {
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: error,
                  timer: 3500
              })
          });
  }


   return (
      <div className="authincation h-100">
         <div className="container w-200" style={{marginRight: '10%'}}>
            <div className="row justify-content-center h-100 align-items-center">
               <div className="col-md-8">
                  <div className="authincation-content">
                     <div className="row no-gutters">
                        <div className="col-xl-12">
                           <div className="auth-form">
                              <h4 className="text-center mb-4">
                                 Iniciar Sesión
                              </h4>
                              <div>
                                 <div className="form-group">
                                    <label className="mb-1">
                                       <strong>Email</strong>
                                    </label>
                                    <input
                                       type="email"
                                       className="form-control"
                                       placeholder="Ingrese su email"
                                       name="Email"
                                       value={email}
                                       onChange={handleEmailChange}
                                    />
                                 </div>
                                 <div className="form-group">
                                    <label className="mb-1">
                                       <strong>Contraseña</strong>
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       placeholder="Ingrese su contraseña"
                                       name="password"
                                       value={password}
                                       onChange={handlePasswordChange}
                                    />
                                 </div>
                                 {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                    {/* <div className="form-group">
                                       <div className="custom-control custom-checkbox ml-1">
                                          <input
                                             type="checkbox"
                                             className="custom-control-input"
                                             id="basic_checkbox_1"
                                          />
                                          <label
                                             className="custom-control-label"
                                             htmlFor="basic_checkbox_1"
                                          >
                                             Remember my preference
                                          </label>
                                       </div>
                                    </div>
                                    <div className="form-group">
                                       <Link to="/page-forgot-password">
                                          Forgot Password?
                                       </Link>
                                    </div>
                                 </div> */}
                                 <div className="text-center">
                                    <input
                                       type="submit"
                                       value="Ingresar"
                                       onClick={() => logn()}
                                       className="btn btn-primary btn-block"
                                    />
                                 </div>
                              </div>
                              <div className="new-account mt-3">
                                 <p>
                                    Si no sabes que hacer, volver a la {" "}
                                    <Link
                                       className="text-primary"
                                       href=""
                                    >
                                       Pagina Principal
                                    </Link>
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Login;
